import AffiliateTable from "../../components/Affiliate/AffiliateTable";
import AffiliateCard from "../../components/Affiliate/AffiliateCard";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "affiliate",
  components: {
    AffiliateTable,
    AffiliateCard,
  },
  props: [],
  data() {
    return {
      dataIndex: [],
      totalInvitedMembers: 0,
      totalMemberReward: 0,
    };
  },
  computed: {
    ...mapGetters({
      getMember: "global/MEMBER",
    }),
    promotionCode() {
      return this.getMember.promotionCode;
    },
  },
  mounted() {
    this.getMemberTotalReward();
    this.getAllInvitedMember();
    this.getTotalInvitedMember();
  },
  methods: {
    ...mapActions({
      getAllInvited: "affiliate/GET_ALL_INVITED_SERVICE",
      getTotalInvited: "affiliate/GET_TOTAL_INVITED_SERVICE",
      getTotalRewardForMember: "affiliate/GET_TOTAL_REWARD_FOR_MEMBER_SERVICE",
    }),
    getMemberTotalReward() {
      this.getTotalRewardForMember().then((response) => {
        this.totalMemberReward = response.data.data;
      });
    },
    getAllInvitedMember() {
      this.getAllInvited().then((response) => {
        this.dataIndex = response.data.data;
      });
    },
    getTotalInvitedMember() {
      this.getTotalInvited().then((response) => {
        this.totalInvitedMembers = response.data.data;
      });
    },
  },
};
