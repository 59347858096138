import BaseIcon from "@/components/UI/BaseIcon";
import VueQriously from "vue-qriously";
import Vue from "vue";
// import {isLocaleLTR} from "@/utiles/languages";
Vue.use(VueQriously);

export default {
  name: "affiliate-card",
  components: {
    BaseIcon,
  },
  props: {
    totalInvited: {
      type: Number,
    },
    totalReward: {
      type: Number,
    },
    promotionCode: {
      type: String,
    },
  },
  data() {
    return {
      showRefCodeModal: false,
    };
  },
  computed: {
    baseUrl() {
      return process.env.VUE_APP_EXCOINO_PANEL_REGISTRATION_URL;
    },
    inviteLink() {
      return this.baseUrl + "?refCode=" + this.promotionCode;
    },
  },
  mounted() {},
  methods: {
      // isLocaleLTR,
    onCopyRefCode() {
      this.$toast.success(this.$t("affiliate.successfulCopyRefCode"));
    },
    onCopyRefLink() {
      this.$toast.success(this.$t("affiliate.successfulCopyRefLink"));
    },
    toggleModal() {
      this.showRefCodeModal = !this.showRefCodeModal;
    },
    onError() {
      this.$toast.error(this.$t("affiliate.successCopy"));
    },
  },
};
