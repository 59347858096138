import { mapActions } from "vuex";

export default {
  name: "affiliate-table",
  components: {},
  props: {
    dataTable: {
      type: Array,
    },
    loading: {
      type: Boolean,
    },
  },
  data() {
    return {
      isRewardsDetailsOpen: false,
      rewardsDetailsData: [],
      rewardsDetailColumns: [
        {
          title: this.$t("affiliate.rewardAmount"),
          key: "totalAmount",
          align: "center",
          minWidth: 160,
          render: (h, params) => {
            return h(
              "span",
              {},
              params.row.totalAmount
                ? "USDT " + params.row.totalAmount
                : "USDT " + 0
            );
          },
        },
        {
          title: this.$t("affiliate.rewardCount"),
          key: "totalCount",
          align: "center",
          minWidth: 120,
        },
      ],
      columns: [],
    };
  },
  computed: {},
  mounted() {

    this.columns = [
      {
        title: this.$t("affiliate.username"),
        key: "username",
        align: this.isLocaleLTR ? "left" : "right",
        minWidth: 180,
        render: (h, params) => {
          return h(
              "div",
              {
                style: {
                  // paddingLeft: "16px",
                  // paddingRight: "16px",
                  direction: "ltr",
                },
              },
              params.row.username
          );
        },
      },
      {
        title: this.$t("affiliate.registerTime"),
        key: "registrationDate",
        align: this.isLocaleLTR ? "left" : "right",
        sortable: true,
        minWidth: 180,
        render: (h, params) => {
          return h("span", {}, this.dateFormat(params.row.registrationDate));
        },
      },
      {
        title: this.$t("affiliate.action"),
        align: this.isLocaleLTR ? "right" : "left",
        className: "affiliateAction",
        minWidth: 180,
        render: (h, params) => {
          let actions = [];
          actions.push(
              h(
                  "Button",
                  {
                    props: {
                      type: "text",
                      size: "small",
                    },
                    style: {
                      border: "1px solid #f0ac19",
                      color: "#f1ac19",
                      padding: "3px 10px",
                      "border-radius": "0",
                      fontSize: "12px",
                    },
                    on: {
                      click: () => {
                        this.isRewardsDetailsOpen = true;
                        this.getRewardOfMember(params.row.memberId);
                      },
                    },
                  },
                  this.$t("affiliate.more")
              )
          );
          return h(
              "div",
              {
                style: {
                  display: "flex",
                  justifyContent: "flex-end",
                  paddingLeft: "16px",
                  paddingRight: "16px",
                },
              },
              actions
          );
        },
      },
    ]
  },
  methods: {
    ...mapActions({
      getTotalRewardFromSpecificMember:
        "affiliate/GET_TOTAL_REWARD_FROM_SPECIFIC_MEMBER_SERVICE",
    }),
    dateFormat: function (tick) {
      return this.$options.filters.dateFormat(tick);
    },
    getRewardOfMember(id) {
      let data = { referredMember: id };
      this.getTotalRewardFromSpecificMember(data).then((response) => {
        this.rewardsDetailsData = [response.data.data];
      });
    },
  },
};
